import { useAuth } from '../hooks/useAuth';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api, { IPaginatedResponse } from '../api';
import { IProject } from '../api/services/project.service';
import NoTeamSection from '../components/dashboard/NoTeamSection';
import MidnightLogoViolet from '../components/global/midnight_violet.png';
import MidnightLogoYellow from '../components/global/midnight_yellow.png';
import { useGlobalState } from '../state';
import ActivitySection from '../components/dashboard/ActivitySection';
import QuickActionSection from '../components/dashboard/QuickActionSection';
import QuickStatsSection from '../components/dashboard/QuickStatsSection';
import { TrackWithFiles } from '../api/services/track.service';
import { ITeamMembership } from '../api/services/team.service';
import { ProjectCard } from '../components/dashboard/ProjectCard';
import { INotification } from '../api/services/notification.service';

function Divider({ text }: { text: string }) {
  return (
    <div className='relative w-full'>
      <div className='absolute inset-0 flex items-center' aria-hidden='true'>
        <div className='w-full border-t border-slate-300 dark:border-slate-600' />
      </div>
      <div className='relative flex justify-center'>
        <span className='bg-slate-200 px-2 text-base text-slate-400 dark:bg-slate-700 dark:text-slate-500'>
          {text}
        </span>
      </div>
    </div>
  );
}

export default function Dashboard() {
  const { user, activeTeam } = useAuth();
  const { theme } = useGlobalState();
  let navigate = useNavigate();

  const [projects, setProjects] = useState<IProject[]>();
  const [tracks, setTracks] = useState<IPaginatedResponse<TrackWithFiles[]>>();
  const [artists, setArtists] = useState<ITeamMembership[]>();
  const [activities, setActivities] = useState<INotification[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tenantLandingImageUrl, setTenantLandingImageUrl] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (activeTeam !== null) {
      fetchProjects();
      if (activeTeam.teamMembershipDTO?.role !== 'GUEST') {
        fetchActivities();
        searchTracks();
        fetchArtists();
      }
      // if (activeTeam.landingImageFileId) {
      //   fetchTenantLandingImage(activeTeam.landingImageFileId);
      // }
    }
  }, [activeTeam]);

  async function fetchTenantLandingImage(fileId: string) {
    try {
      const urlResponse = await api.file.getFilePresignedDownloadUrl(fileId);
      setTenantLandingImageUrl(urlResponse.data.result.presignedUrl);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function fetchActivities() {
    try {
      setLoading(true);
      const { data } = await api.notification.getNotifications();
      if (data.result) {
        setActivities(
          data.result.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        );

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
  }

  async function fetchProjects() {
    try {
      setLoading(true);
      const { data } = await api.project.getProjects();
      if (data.result) {
        setProjects(data.result);
        setLoading(false);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function searchTracks() {
    try {
      setLoading(true);
      const response = await api.track.searchTrack({
        size: 5,
      });
      if (response.data.result) {
        setTracks(response.data);
      }
      setLoading(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function fetchArtists() {
    try {
      if (!activeTeam) {
        return;
      }

      setLoading(true);
      const response = await api.team.getTeamMembers(activeTeam.id);
      if (response.data.result) {
        setArtists(response.data.result);
      }
      setLoading(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  const statusOrder = ['DRAFT', 'REQUESTED', 'SUBMITTED', 'DONE'];

  return (
    <>
      {user && (
        <div className='flex grow flex-col items-center px-4 md:px-8 lg:px-16 xl:px-24'>
          {/* {activeTeam && tenantLandingImageUrl && (
            <img
              className='mt-4 max-h-32'
              src={tenantLandingImageUrl}
              alt='MidnightLogoYellow'
            />
          )} */}
          {/* {activeTeam && !activeTeam.landingImageFileId && ( */}

          {/* )} */}
          {!activeTeam && (
            <div className='mt-16 w-full'>
              <NoTeamSection />
            </div>
          )}
          {activeTeam && (
            <div className=''>
              {theme === 'dark' ? (
                <img
                  className='mt-4 max-h-16'
                  src={MidnightLogoYellow}
                  alt='MidnightLogoYellow'
                />
              ) : (
                <img
                  className='mt-4 max-h-16'
                  src={MidnightLogoViolet}
                  alt='MidnightLogoViolet'
                />
              )}
            </div>
          )}
          {activeTeam &&
            user &&
            activeTeam.teamMembershipDTO?.role !== 'GUEST' && (
              <div className='mt-16 grid w-full grid-cols-3 gap-12 lg:gap-20'>
                <div className='col-span-full space-y-12 lg:col-span-2 '>
                  <QuickStatsSection
                    projects={projects}
                    tracks={tracks}
                    artists={artists}
                  />
                  <ActivitySection activities={activities} className='mt-6' />
                </div>
                <div className='order-first col-span-full lg:order-last lg:col-span-1'>
                  <QuickActionSection />
                </div>
              </div>
            )}
          {activeTeam &&
            user &&
            activeTeam.teamMembershipDTO?.role === 'GUEST' && (
              <div className='flex flex-col items-center'>
                <div className='mt-4 text-lg leading-8 text-slate-600 dark:text-slate-300'>
                  You have been invited to submit your tracks to
                </div>
                <b className='mb-6 text-blue-500'>{activeTeam.name}</b>
                <Divider text='Active submissions' />
                <div className='mt-6 mb-16 grid w-full grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                  {projects
                    ?.filter((p) => p.status !== 'DONE')
                    .sort((a, b) => {
                      // First, compare by status
                      const statusComparison =
                        statusOrder.indexOf(a.status) -
                        statusOrder.indexOf(b.status);
                      if (statusComparison !== 0) {
                        return statusComparison;
                      }
                      // If statuses are the same, compare by createdAt
                      return a.createdAt > b.createdAt ? -1 : 1;
                    })
                    .map((project) => (
                      <ProjectCard
                        project={project}
                        action={() => navigate(`/submission/${project.id}`)}
                      />
                    ))}
                </div>
                {projects &&
                  projects.filter((p) => p.status === 'DONE').length > 0 && (
                    <>
                      <Divider text='Completed submissions' />
                      <div className='mt-6 grid w-full grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                        {projects
                          ?.filter((p) => p.status === 'DONE')
                          .map((project) => (
                            <ProjectCard
                              project={project}
                              action={() =>
                                navigate(`/submission/${project.id}`)
                              }
                            />
                          ))}
                      </div>
                    </>
                  )}
              </div>
            )}
        </div>
      )}
    </>
  );
}
